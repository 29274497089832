import { Component, HostBinding, HostListener, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from "./shared/services/config/config.service";
import { PreloaderService } from "./shared/services/preloader/preloader.service";
import { SpinnerService } from "./shared/services/spinner/spinner.service";
import { filter, map, mergeMap } from 'rxjs/operators';
declare var $: any;

@Component({
	selector: "app-root",
	template: "<router-outlet></router-outlet>"
})

export class AppComponent implements OnInit
{
	//App Left Sidebar Menu Open/Close Desktop
	@HostBinding("class.app_sidebar-menu-collapsed")
	get isApp_SidebarLeftCollapsed()
	{
		return this.config.appLayout.isApp_SidebarLeftCollapsed;
	}
	//Left Menu Sidebar Open/Close Tablet & Mobile
	@HostBinding("class.app_sidebar-left-open")
	get isApp_MobileSidebarLeftOpen()
	{
		return this.config.appLayout.isApp_MobileSidebarLeftOpen;
	}
	//App Right Sidebar Open/Close
	@HostBinding("class.sidebar-overlay-open")
	get isApp_SidebarRightOpen()
	{
		return this.config.appLayout.isApp_SidebarRightOpen;
	}

	// constructor
	constructor
		(
			public config: ConfigService,
			private spinnerService: SpinnerService,
			private titleService: Title,
			private activatedRoute: ActivatedRoute,
			private router: Router,
			private translateService: TranslateService,
			private metaService: Meta
		)
	{
		// this language will be used as a fallback when a translation isn't found in the current language
		translateService.setDefaultLang('it');

		// get user lang from localstorage
		let _userLanguage = localStorage.getItem('user_lang') || 'it';

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translateService.use(_userLanguage);
	}

	public setTitle(newTitle: string)
	{
		this.titleService.setTitle(newTitle);
	}

	// init
	ngOnInit()
	{
		// setting page title and meta tags
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.activatedRoute),
				map((route: ActivatedRoute) =>
				{
					while (route.firstChild) route = route.firstChild;
					return route;
				}),
				filter((route: ActivatedRoute) => route.outlet === 'primary'),
				mergeMap((route: ActivatedRoute) => route.data)
			)
			.subscribe((event) =>
			{
				this.translateService.get(event['title']).subscribe((translated: string) =>
				{
					this.titleService.setTitle(translated + ' - pCode')
				});

				// custom meta tags from routing data, 'metatags' property
				const tags: [] = event['metatags'];
				if (tags)
				{
					for (const tag in tags)
						this.metaService.updateTag({ property: tag, content: tags[tag] });
				}
			});

		$(document).on("click", '[href="#"]', e => e.preventDefault());
		this.router.events.subscribe((evt) =>
		{
			if (!(evt instanceof NavigationEnd))
			{
				return;
			}
			$("body,html").scrollTop(0, 0);
		});
	}

	//check if menu should reset on resize
	@HostListener("window:resize")
	public onWindowResize(): void
	{
		if (this._shouldMenuReset())
		{
			this.config.appLayout.isApp_SidebarLeftCollapsed = false;
		}
	}

	private _shouldMenuReset(): boolean
	{
		return window.innerWidth <= this.config.breakpoint.desktopLG;
	}

	public ngAfterViewInit(): void
	{
		PreloaderService.load().then(values =>
		{
			this.spinnerService.hide();
		});
	}
}
