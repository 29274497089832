import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";

const routes: Routes =
	[
		{ path: "", loadChildren: () => import('./layout.module').then(m => m.LayoutModule) }
	];

const routerOptions: ExtraOptions =
{
	useHash: false,
	anchorScrolling: 'enabled',
	scrollPositionRestoration: 'enabled'
};

@NgModule({
	imports:
		[
			RouterModule.forRoot(routes, routerOptions)
		],
	exports:
		[
			RouterModule
		]
})
export class AppRoutingModule { }
