import { NgModule, Optional, SkipSelf } from "@angular/core";
import { WeatherService } from './../components/weather/weather.service';
import { AjaxLoaderService } from "./ajaxLoader/ajaxLoader.service";
import { ConfigService } from "./config/config.service";
import { PCodeServices } from './pcode/pcode.service';
import { PreloaderService } from "./preloader/preloader.service";
import { SpinnerService } from "./spinner/spinner.service";

@NgModule({
	imports: [],
	providers: [ConfigService, PreloaderService, SpinnerService, AjaxLoaderService, PCodeServices, WeatherService],
	declarations: [],
	exports: []
})
export class ServicesModule
{
	constructor
		(
			@Optional()
			@SkipSelf()
			parentModule: ServicesModule
		)
	{
	}
}
