import { NgxUploaderModule } from '@angular-ex/uploader';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import localeEN from '@angular/common/locales/en';
import localeENExtra from '@angular/common/locales/extra/en';
import localeITExtra from '@angular/common/locales/extra/it';
import localeIT from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastNoAnimationModule } from 'ngx-toastr';
import * as R from 'ramda';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { GlobalState } from "./app.state";
import { AlertsService } from './shared/services/alerts/alerts.service';
import { LocalStorageTodoService } from './shared/services/localstorage/localstorage-todo.service';
import { NotificationService } from './shared/services/notification/notification.service';
import { JwtHttpInterceptor } from './shared/services/pcode/JwtHttpInterceptor';
import { ServicesModule } from "./shared/services/services.module";
import { SharedModule } from './shared/shared.module';

const USER_LOCALE = localStorage.getItem('user_lang') || 'it';
switch (USER_LOCALE)
{
	case 'it':
		registerLocaleData(localeIT, localeITExtra);
		break;

	case 'en':
		registerLocaleData(localeEN, localeENExtra);
		break;

	default:
		registerLocaleData(localeIT, localeITExtra);
		break;
}

const APP_PROVIDERS =
	[
		{ provide: LOCALE_ID, useValue: USER_LOCALE },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtHttpInterceptor, multi: true },
		GlobalState,
		Title,
		LocalStorageTodoService,
		NotificationService,
		AlertsService
	];

export function HttpLoaderFactory(http: HttpClient)
{
	return new MultiTranslateHttpLoader(http, [
		{ prefix: "/assets/translate/core/", suffix: ".json" },
		{ prefix: "/assets/translate/app/", suffix: ".json" }
	]);
}

export class MultiTranslateHttpLoader implements TranslateLoader
{
	constructor(private http: HttpClient, public resources: { prefix: string; suffix: string }[] = [{ prefix: '/assets/translate/', suffix: '.json' }]) { }

	public getTranslation(lang: string)
	{
		return forkJoin(this.resources.map(config =>
		{
			return this.http.get(config.prefix + lang + config.suffix);
		}))
			.pipe(map(response =>
			{
				return (response as [][]).reduce((a, b) =>
				{
					return R.mergeDeepLeft(a, b);
				});
			}));
	}
}

export function getToken(): string
{
	return localStorage.getItem('access_token');
}

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ServicesModule,
        SharedModule,
        ToastNoAnimationModule.forRoot({
            timeOut: 3500,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxUploaderModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })], providers: [APP_PROVIDERS, provideHttpClient(withInterceptorsFromDi())] })

export class AppModule
{
}
